
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import Header from '../components/header/Header.vue';
import isUser from '../context/user';
import User from '../types/User';
import AnimeItem from '../components/home/AnimeItem.vue';
import Anime from '../types/Anime';

export default defineComponent({
  components: { Header, AnimeItem },
  setup() {
    const route = useRoute();
    const { type } = route.params;
    const listAnime = ref<Anime[] | null>(null);

    const userCheck = ref(false);
    const user = ref<User | null>(null);

    isUser(userCheck);
    if (userCheck.value) {
      user.value = JSON.parse(localStorage.getItem('user') || '{}');
    }

    const fetchListAnime = async () => {
      try {
        if (user.value?.access_token) {
          const response = await fetch(
            `${process.env.VUE_APP_BACKEND_URL}/list/${user.value?.access_token}/${type}`
          );
          if (!response.ok) {
            throw Error('No data available');
          }
          const json = await response.json();
          const jsonObj = await JSON.parse(json);
          listAnime.value = jsonObj.data;
        }
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    fetchListAnime();

    return { type, listAnime };
  }
});
